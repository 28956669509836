import { FlexContainer } from "@/uikit/container";
import { Block } from "../base"
import { BlockContainer } from "../base/block-container";
import { Typography } from "@/uikit/Typography";
import React, { useEffect, useState } from "react";
import * as styles from './contactUs.css'
import useFormValidation from './useFormValidation';
import IconCheck from '../../svg/icons/iconCheckGreen.svg';



const ContactUs = Block("contactUs", ({ title, subtitle, contactForm, }) => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [corporateName, setCorporateName] = useState('');
    const [bedCount, setBedCount] = useState('');
    const [facilityType, setFacilityType] = useState('');

    const [status, setStatus] = useState<string | null>(null);
    const [submittedName, setSubmittedName] = useState('');

    const { errors, validate } = useFormValidation();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const isValid = validate({
            fullName, email, phoneNumber, corporateName, bedCount, facilityType,
        });

        if (!isValid) {
            return;
        }

        const response = await fetch('/api/send-form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fullName, email, phoneNumber, corporateName, bedCount, facilityType }),
        });

        if (response.ok) {
            setSubmittedName(fullName);
            setStatus('SUCCESS');
            setFullName('');
            setEmail('');
            setPhoneNumber('');
            setCorporateName('');
            setBedCount('');
            setFacilityType('');
        } else {
            setStatus('ERROR');
        }
    };
    useEffect(() => {
        if (status) {
            const timer = setTimeout(() => {
                setStatus(null);
                setSubmittedName('');
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [status]);
    return (
        <BlockContainer id="contact-us" layout="centeredColumn" blockPaddingTop="medium" className={styles.contactWrapper}>
            <FlexContainer alignItems="center" direction="column" gap="24" className={styles.titleWrapper} justifyContent="center">
                <Typography settings={{ tag: "heading" }} className={styles.title}>
                    {title && title.map((part: any, index: any) => (
                        <React.Fragment key={part._key}>
                            <span className={styles.titleColor({ color: part.color })}>
                                {part.text}
                            </span>
                            {index < title.length - 1 && ' '}
                        </React.Fragment>
                    ))}
                </Typography>
                <Typography settings={{ tag: "description" }}>
                    {subtitle}
                </Typography>
            </FlexContainer>
            {contactForm && (
                <FlexContainer className={styles.formWrapper} direction="column" gap="32" alignItems="start" justifyContent="center">
                    <span className={styles.formTitle}>{contactForm.title}</span>
                    {status === 'SUCCESS' && (
                        <div className={styles.successWrapper}>
                            <div className={styles.checkWrapper}><IconCheck /></div>
                            <Typography settings={{ tag: 'message', mode: 'success' }}>Thank you {submittedName}. We received your message and will contact you soon</Typography>
                        </div>
                    )}
                    {status === 'ERROR' && (
                        <div className={styles.errorWrapper}>
                            <Typography settings={{ tag: 'message', mode: 'error' }}>There was an error. Please try again.</Typography>
                        </div>
                    )}
                    <form action="" className={styles.formBox} onSubmit={handleSubmit}>
                        <label className={styles.formItem}>
                            {contactForm.name}
                            <input type="text" placeholder="Enter Your Name" className={styles.formInput({ height: 'default' })} value={fullName} onChange={(e) => setFullName(e.target.value)} />
                            {errors.fullName && (
                                <Typography settings={{ tag: 'error', mode: 'error' }}>{errors.fullName}</Typography>
                            )}
                        </label>
                        <label className={styles.formItem}>
                            {contactForm.email}
                            <input type="email" placeholder="Enter Your Email Address" className={styles.formInput({ height: 'default' })} value={email} onChange={(e) => setEmail(e.target.value)} />
                            {errors.email && (
                                <Typography settings={{ tag: 'error', mode: 'error' }}>{errors.email}</Typography>
                            )}
                        </label>
                        <label className={styles.formItem}>
                            {contactForm.phone}
                            <input type="tel" placeholder="Enter Your Phone Number" className={styles.formInput({ height: 'default' })} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                            {errors.phoneNumber && (
                                <Typography settings={{ tag: 'error', mode: 'error' }}>{errors.phoneNumber}</Typography>
                            )}
                        </label>
                        <label className={styles.formItem}>
                            {contactForm.corporateName}
                            <input type="text" placeholder="Enter Your Facility Name" className={styles.formInput({ height: 'default' })} value={corporateName} onChange={(e) => setCorporateName(e.target.value)} />
                            {errors.corporateName && (
                                <Typography settings={{ tag: 'error', mode: 'error' }}>{errors.corporateName}</Typography>
                            )}
                        </label>
                        <label className={styles.formItem}>
                            Bed Count
                            <select
                                className={`${styles.formInput({ height: 'select', formType: 'select' })} ${bedCount === '' ? styles.optionTextStyle : ''}`}
                                value={bedCount}
                                onChange={(e) => setBedCount(e.target.value)}
                            >
                                <option value="" className={styles.optionTextStyle}>Select Bed Count</option>
                                {contactForm?.bedCountOptions?.map((option: string, index: number) => (
                                    <option key={index} value={option}>{option}</option>
                                ))}
                            </select>
                            {errors.bedCount && (
                                <Typography settings={{ tag: 'error', mode: 'error' }}>{errors.bedCount}</Typography>
                            )}
                        </label>
                        <label className={styles.formItem}>
                            Facility Type
                            <select
                                className={`${styles.formInput({ height: 'select', formType: 'select' })} ${facilityType === '' ? styles.optionTextStyle : ''}`}
                                value={facilityType}
                                onChange={(e) => setFacilityType(e.target.value)}
                            >
                                <option value="">Select Facility Type</option>
                                {contactForm?.facilityTypeOptions?.map((option: string, index: number) => (
                                    <option key={index} value={option}>{option}</option>
                                ))}
                            </select>
                            {errors.facilityType && (
                                <Typography settings={{ tag: 'error', mode: 'error' }}>{errors.facilityType}</Typography>
                            )}
                        </label>
                        <button type="submit" className={styles.buttonStyle}>
                            <Typography settings={{ mode: "light", tag: "button" }} className={styles.buttonText}>
                                {contactForm.buttonText}
                            </Typography>
                        </button>
                    </form>
                </FlexContainer>
            )}
        </BlockContainer>
    )
})

export default ContactUs;