import { useState } from "react";

interface ValidationErrors {
  fullName: string;
  email: string;
  phoneNumber: string;
  message?: string;
  corporateName: string;
  bedCount: string;
  facilityType: string;
}

const validateEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const validatePhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.startsWith("+1")) {
    return /^\+1\d{10}$/.test(phoneNumber);
  } else if (phoneNumber.startsWith("1")) {
    return /^\+1\d{10}$/.test(phoneNumber);
  } else {
    return /^\d{10,15}$/.test(phoneNumber);
  }
};

const useFormValidation = () => {
  const [errors, setErrors] = useState<ValidationErrors>({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
    corporateName: "",
    bedCount: "",
    facilityType: "",
  });

  const validate = (values: {
    fullName: string;
    email: string;
    phoneNumber: string;
    corporateName: string;
    bedCount: string;
    facilityType: string;
  }) => {
    let isValid = true;
    const newErrors: ValidationErrors = {
      fullName: "",
      email: "",
      phoneNumber: "",

      corporateName: "",
      bedCount: "",
      facilityType: "",
    };

    if (values.fullName.trim() === "") {
      newErrors.fullName = "Full name is required";
      isValid = false;
    }
    if (!validateEmail(values.email)) {
      newErrors.email = "Invalid email address";
      isValid = false;
    }
    if (!validatePhoneNumber(values.phoneNumber)) {
      newErrors.phoneNumber = "Invalid phone number";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  return { errors, validate };
};

export default useFormValidation;
