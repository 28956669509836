import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fblocks%2FContactUs%2FcontactUs.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA7VWf2%2BjOBD9v59iRLXaVrckQAgQt6m26Xa%2FhwOGOAWMbBPonvLdTzY%2FgjdUu3u6U9ukmnmeeZ4ZP3tBV%2B%2BioC78fQMQs5xxBLeO4zzcnG8Wnc8zfO5zGH73Ju6VdkvSShvnNCsRxKSUhE8gvoZUOElomdmSVQgip2ofJsY9k5IVCDZBZ9%2Fj%2BC3jrC4TBDXP7z4vsRBEiiUtsmXMSoljaV9Ai2OVfb43F9qC%2FiAIYnZSZAxPxQSVlM1xXWuuBW7thibygCDww3mqK0%2FZx4WBXsj2RxJLO6VyzDwiQo3ow%2FrR2gg7xDO3fpumqbYxnhBuc5zQWiDw%2BurtWWuLA05Yg8CpWnDVh68%2B1B%2FP9vjO%2BQL978J17idsIs0moaLK8TuCNCc6pPq2E8pJ3BeI5XVRKk%2BGKwSe32Xud%2BE6zqdJzM2fxhymyvd9DWSl7Nvm9om0qSE0O0gEgRpMgJyWxD70to5CTy8yWoKnBR9wxpDPD65BZGiLQcSfI%2BIbtdjr5DqyLUieIhCSExkfZvoZDe1UVgRu1YJgOU3g9nXz%2Bvz6YsyJ2xP6aSPT2gX%2FkjJKWVwL%2B0QF3eekG%2BhaqiUISlaSD0heaUKslw5Z%2FLXRlcR0BoaTGM6oLwwn3cY6DiM47UpcVQRzXMYTknZD9m9U2rO%2Bgv2YdUwEghY4I73yJFhipA1Lccr%2Baov8oZZp9OVRnDJoi7wUW%2BsgZYWWy6ZpFs1qwXi29BzHUXgLTpQ0O9ZuLQcc8HzwfAtSmudbSyW21FiwN7K14ppzUsoX1dbB2onQ1vJGg2pGjKutpYka5iOj5WB%2FeqxY%2Fq6sUDFaSrG1AtiA64G7BjeCjfX0uBwgT4%2BK6dOVgHJSESxVgfp%2FP5RRrvrVyc%2FlEF1JsZrLcTgHOeVdq1e9pMU1F2qsNWtDPtP%2FajqzeZW6HDC1jUFij7WQNH231Z1DSjkVie5sU0kKMTVrIXKdGZ28OvebjXnwrydxcuPO1EZdVDyjZXendtp82SY6qAtIb9YI6ISB9%2B3ZQOJY0hOZgQbr3ctuAj1ozK%2B0Jvy11tDfuyo4a2bKeKWFf96lnzox8%2By43e2%2BR6pOfUvN6h7n%2Bf%2FvRIfBMpgSb0M24YTd2zy7UXT7IEZKXXohMZezRKfuMU9uvA3XvvpR7q8FSSiGu8k7ynW8VdXe6wXGu9B4bQ2Fu%2BgDLbsTPmz8fFkffrz%2BPE8iDMIPOFylW1%2BlC3roWNbhuE4wuMdMTsjwZDrfnP8BjphgA24LAAA%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var bgImgStyle = 'i3ysmi6';
export var buttonStyle = 'i3ysmig';
export var buttonText = 'i3ysmih';
export var checkWrapper = 'i3ysmik';
export var contactWrapper = 'i3ysmi4';
export var errorWrapper = 'i3ysmij';
export var formBox = 'i3ysmi8';
export var formInput = _7a468({defaultClassName:'i3ysmib',variantClassNames:{height:{'default':'i3ysmic',select:'i3ysmid',textarea:'i3ysmie'},formType:{select:'i3ysmif'},color:{}},defaultVariants:{},compoundVariants:[]});
export var formItem = 'i3ysmi9';
export var formTitle = 'i3ysmia';
export var formWrapper = 'i3ysmi7';
export var optionTextStyle = 'i3ysmil';
export var successWrapper = 'i3ysmii';
export var title = 'i3ysmi3';
export var titleColor = _7a468({defaultClassName:'i3ysmi0',variantClassNames:{color:{black:'i3ysmi1',blue:'i3ysmi2'}},defaultVariants:{},compoundVariants:[]});
export var titleWrapper = 'i3ysmi5';